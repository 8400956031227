import { formsHttpClient } from "../api";

const getMessageById = async id => {
  let result = "";

  await formsHttpClient.get(`/msg/${id}`).then(response => {
    result = response.data;
  });

  return result;
};

export default {
  getMessageById
};
