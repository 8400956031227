<template>
  <div class="forms-unavailable-msg">
    <div v-if="status == 'NO_LONGER_ACTIVE'" class="info-content">
      <info-icon size="4.0x" class="info-icon small-buffer" />
      <h2 v-if="spanish" class="small-buffer">
        Lo sentimos, no podemos recuperar el enlace; el remitente recordó el mensaje
      </h2>
      <h2 v-else class="small-buffer">
        Sorry, we cannot retrieve the link; the sender recalled the message
      </h2>
    </div>
    <div v-else-if="status == 'UNIQUE_ID_NOT_FOUND'" class="info-content">
      <alert-circle-icon size="4.0x" class="error-icon small-buffer" />
      <h2 v-if="spanish" class="small-buffer">
        Lo sentimos, no podemos recuperar el enlace; por favor verifíquelo dos veces o téngalo resentirse de
      </h2>
      <h2 v-else class="small-buffer">
        Sorry, we cannot retrieve the link; please double-check or have it resent
      </h2>
    </div>
  </div>
</template>

<script>
  import { InfoIcon, AlertCircleIcon } from "vue-feather-icons";
  import {spanishOrg, userLocale} from "@/utils/formatting";
  export default {
    name: "FormMessageNotFound",
    components: { InfoIcon, AlertCircleIcon },
    props: {
      status: null,
      language: null
    },
    computed: {
      spanish: function() {
        return (this.language?.toUpperCase() === "SPANISH" || userLocale().startsWith("es"));
      }
    },
    methods: {
      spanishOrgType: function(org) {
        return spanishOrg(org);
      }
    }
  };
</script>

<style scoped lang="scss">
  .forms-unavailable-msg {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 5%;
    margin-bottom: 5%;

    .info-content {
      display: inherit;
      justify-content: inherit;
      align-items: inherit;
      flex-direction: inherit;
    }
  }

  .info-icon {
    color: blue;
  }
  .error-icon {
    color: red;
  }

  .strong-highlight {
    background-color: yellow;
  }

  .small-buffer {
    margin-bottom: 5px;
  }
</style>
