import moment from "moment";

let userLocale = () => {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0];
  } else {
    return (
      navigator.language ||
      navigator.userLanguage ||
      navigator.browserLanguage ||
      navigator.systemLanguage ||
      "en"
    );
  }
};

let spanishOrg = orgType => {
  if (!orgType) {
    return "el remitente de SMS";
  } else if (orgType?.toUpperCase() === "pharmacy") {
    return "su farmacia";
  } else {
    // Medical Provider
    return "su proveedor médico";
  }
};

let formatUpdoxPhone = phoneNumber => {
  if (phoneNumber && (phoneNumber.length == 10 || phoneNumber.length == 11)) {
    let base = 0;
    if (phoneNumber.length > 10) {
      base = 1;
    }
    return (
      phoneNumber.substr(0 + base, 3) +
      "-" +
      phoneNumber.substr(3 + base, 3) +
      "-" +
      phoneNumber.substr(6 + base, 4)
    );
  }
  return phoneNumber || "";
};

let formatDateToAmerican = date => {
  return moment(date, "YYYY-MM-DD").format("MM/DD/YYYY");
};

export { formatUpdoxPhone, formatDateToAmerican, userLocale, spanishOrg };
