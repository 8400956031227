<template>
  <div class="forms-unavailable-msg">
    <info-icon size="4.0x" class="info-icon small-buffer" />
    <div class="info-content">
      <h2 v-if="expired && spanish" class="small-buffer">
        Lo siento, tu
        <span v-if="category === 'broadcast'">mensaje de difusión</span>
        <span v-else-if="category === 'reminder'">recordatorio de cita</span>
        <span v-else>mensaje</span>
        ha caducado
      </h2>
      <h2 v-else-if="expired" class="small-buffer">
        Sorry, your
        <span v-if="category === 'broadcast'">broadcast message</span>
        <span v-else-if="category === 'reminder'">appointment reminder</span>
        <span v-else>message</span>
        has expired
      </h2>
      <h2 v-else-if="cancelled && spanish" class="small-buffer">
        Lo sentimos, se canceló el recordatorio de la cita del
        {{ appointmentDate }}
      </h2>
      <h2 v-else-if="cancelled" class="small-buffer">
        Sorry, the appointment reminder on {{ appointmentDate }} has been
        cancelled.
      </h2>
      <h2 v-else-if="restructured && spanish" class="small-buffer">
        Lo sentimos, el enlace de recordatorio de su cita solía estar el
        {{ appointmentDate }}. Por favor busque un enlace más nuevo dentro de
        SMS.
      </h2>
      <h2 v-else-if="restructured" class="small-buffer">
        Sorry, your appointment reminder link used to be on
        {{ appointmentDate }}. Please check for a newer link within SMS.
      </h2>
      <h2 v-else-if="locked && spanish" class="small-buffer">
        Lo sentimos, su enlace de recordatorio de cita ha sido bloqueado.
      </h2>
      <h2 v-else-if="locked" class="small-buffer">
        Sorry, your appointment reminder link has been locked out.
      </h2>
      <h2 v-else-if="spanish" class="small-buffer">
        Lo siento, tu
        <span v-if="category === 'broadcast'">mensaje de difusión</span>
        <span v-else-if="category === 'reminder'">recordatorio de cita</span>
        <span v-else>mensaje</span>
        no está disponible
      </h2>
      <h2 v-else class="small-buffer">
        Sorry, your
        <span v-if="category === 'broadcast'">broadcast message</span>
        <span v-else-if="category === 'reminder'">appointment reminder</span>
        <span v-else>message</span>
        is unavailable
      </h2>
      <p v-if="spanish" class="small-buffer">
        Comuníquese con <em>{{ spanishOrgType(orgType) }}</em> para obtener
        ayuda
      </p>
      <p v-else class="small-buffer">
        Please contact <em>{{ englishOrgType(orgType) }}</em> for assistance
      </p>
    </div>
  </div>
</template>

<script>
  import { InfoIcon } from "vue-feather-icons";
  import { userLocale, spanishOrg } from "@/utils/formatting";
  export default {
    name: "FormMessageUnavailable",
    components: { InfoIcon },
    props: {
      orgType: null,
      expired: null,
      cancelled: null,
      locked: null,
      restructured: null,
      appointmentDate: String,
      category: null,
      language: null
    },
    computed: {
      spanish: function() {
        return (this.language?.toUpperCase() === "SPANISH" || userLocale().startsWith("es"));
      }
    },
    methods: {
      spanishOrgType: function(org) {
        return spanishOrg(org);
      },
      englishOrgType: function(org) {
        if (!org) {
          return "the SMS Sender";
        } else {
          return `your ${org}`;
        }
      }
    }
  };
</script>

<style scoped lang="scss">
  .forms-unavailable-msg {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 5%;
    margin-bottom: 5%;

    .info-content {
      display: inherit;
      justify-content: inherit;
      align-items: inherit;
      flex-direction: inherit;
    }
  }

  .info-icon {
    color: blue;
  }
  .strong-highlight {
    background-color: yellow;
  }

  .small-buffer {
    margin-bottom: 5px;
  }
</style>
