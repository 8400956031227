<!-- What is this? We're displaying Broadcast (Blast!) messages as plain text. -->
<template>
  <div class="message">
    <div v-if="loading" class="loading-wrapper">
      <updox-loading color="#003462" size="80px" />
    </div>
    <div v-else-if="error">
      <updox-error :error-details="errorDetails" />
    </div>
    <div
      v-else-if="
        message.status === 'MESSAGE_EXPIRED' ||
          message.status === 'PRACTICE_NO_BROADCAST'
      "
    >
      <form-message-unavailable
        :expired="message.status === 'MESSAGE_EXPIRED'"
        :org-type="message.orgType"
        :category="message.category"
        :language="message.language"
      />
    </div>
    <div
      v-else-if="
        message.status == 'UNIQUE_ID_NOT_FOUND' ||
          message.status == 'NO_LONGER_ACTIVE'
      "
    >
      <form-message-not-found
        :status="message.status"
        :language="message.language"
      />
    </div>
    <div v-else class="padding-20">
      <div class="message-container" :id="`message-${message.id}`">
        <br />
        <div class="practice-info">
          <div class="name">
            <span
              ><b>{{
                message.practiceNameUnlessHipaaSafePracticeNameProvided
              }}</b></span
            >
          </div>
          <div class="phoneNumber" v-if="message.practicePhoneNumber">
            <span><b>Phone:</b> {{ message.practicePhoneNumber }}</span>
          </div>
          <div class="expirationDate" v-if="message.expirationDate">
            <span><b>Expires:</b> {{ message.expirationDate }}</span>
          </div>
        </div>
        <div class="message-body">
          <p
            v-if="message.type === 'INLINE' && message.html"
            v-html="message.html"
            class="message-inline"
          ></p>
          <div
            v-if="message.type === 'BLOCK' && message.html"
            v-html="message.html"
            class="message-block"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { UpdoxLoading } from "@updox/common-vue";
  import UpdoxError from "../components/common/UpdoxError";
  import FormMessageUnavailable from "../components/common/FormMessageUnavailable";
  import FormMessageNotFound from "../components/common/FormMessageNotFound";
  import FormMessageService from "../services/FormMessageService";
  import airbrake from "../logging/logging";
  import * as DOMPurify from "dompurify";
  import { formatUpdoxPhone, formatDateToAmerican } from "@/utils/formatting";

  export default {
    name: "EventNotifyMessage",
    components: {
      FormMessageUnavailable,
      FormMessageNotFound,
      UpdoxError,
      UpdoxLoading
    },
    data: () => ({
      loading: true,
      message: null,
      error: false,
      errorDetails: null
    }),
    async mounted() {
      const msgId = this.$route.params.msgId;
      await FormMessageService.getMessageById(msgId)
        .then(response => {
          if (!response || Object.keys(response).length === 0) {
            this.error = true;
            this.errorDetails = {
              errorType: "emptyMessage"
            };
            console.error(
              `Error loading form message with id: ${msgId}. Null or empty response.`
            );
          }

          this.message = response;
          // Santize the data, should there be any ID or NAME attributes isolate them from JS variables
          // by prefixing them with the string `user-content-`
          this.message.html = DOMPurify.sanitize(this.message.html, {
            USE_PROFILES: { html: true },
            SANITIZE_NAMED_PROPS: true
          });

          this.message.practicePhoneNumber = formatUpdoxPhone(
            this.message.practicePhoneNumber
          );
          if (this.message.expirationDate) {
            this.message.expirationDate = formatDateToAmerican(
              this.message.expirationDate
            );
          }
          airbrake.addFilter(notice => {
            notice.params.msgId = msgId;
            return notice;
          });
        })
        .catch(e => {
          if (e?.response?.status === 400) {
            this.message = {
              status: 'UNIQUE_ID_NOT_FOUND'
            };
          } else {
            this.error = true;
            this.errorDetails = {
              errorType: "emptyMessage"
            };
          }
          console.error(`Error loading form message with id: ${msgId}`, e);
          airbrake.notify({
            error: e,
            message: `Error loading form message with id: ${msgId}`
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  };
</script>

<style lang="scss">
  .loading-wrapper {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .header {
    display: none !important;
    visibility: hidden;
  }

  .loading-wrapper {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .padding-20 {
    padding: 20px;
  }

  .padding-10 {
    padding: 10px;
  }

  .message-container {
    padding-left: 40px;
    padding-right: 40px;
    transition-duration: 0.2s;
    background-color: white;
    display: flex;
    flex-direction: column;

    @media (max-width: 1024px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .message {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
  }

  div.practice-info {
    background-color: #f2f2f2;
    color: #4a4a4a;
    font-size: 16px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: hidden;
    box-sizing: border-box;
  }

  div.practice-info div {
    flex: 1 1;
    min-width: 200px;
    padding: 5px 20px 5px 20px;
    justify-content: space-between;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: break-spaces;
  }

  .message-body {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .message-field {
    flex-direction: column;
    margin-top: 1em;
  }

  .message-inline {
    @extend .message-field;
    overflow-wrap: anywhere;
    tab-size: 2;
    white-space: pre-wrap;
  }

  .message-block {
    @extend .message-field;
    display: flex;
  }
</style>
